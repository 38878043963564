/* eslint-disable */
// Used for :
// https://github.com/recharts/recharts/issues/2491

// Ci dessous methode stylé mais peut etre dangereuse
// Donc on préfère le load synchrone
// if (typeof window !== "undefined") {
//   if (!window.ResizeObserver) {
//     import(/* webpackChunkName: "resize-observer-polyfill" */ 'resize-observer-polyfill').then((module) => {
//       window.ResizeObserver = module.default;
//       console.log('[polyfill] ResizeObserver');
//     });
//   }
// }

import ResizeObserver from 'resize-observer-polyfill';

if (typeof window !== "undefined") {
  if (!window.ResizeObserver) {
    window.ResizeObserver = ResizeObserver
  }
}
